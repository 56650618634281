import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Icons } from 'src/app/constants/icons';
import { MobileOtpComponent } from '../mobile-otp/mobile-otp.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from '../../auth.service';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import { CryptoEncryptionService } from 'src/app/services/crypto-encryption.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { FirebaseMessagingService } from 'src/app/services/firebase-messaging.service';
@Component({
  selector: 'app-aadhar-list',
  templateUrl: './aadhar-list.component.html',
  styleUrls: ['./aadhar-list.component.scss'],
})
export class AadharListComponent {
  aadharListForm!: FormGroup;
  icon: any;
  aadharList: any = [];
  comeFromApp: boolean = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public loaderService: LoaderService,
    public authService: AuthService,
    private toastr: ToastrService,
    private store: StoreService,
    private encrypt: CryptoEncryptionService,
    private firebaseService: FirebaseMessagingService,
    public dialogRef: MatDialogRef<MobileOtpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private analytics: AnalyticsService
  ) {
    this.icon = Icons;
    this.aadharList = data.aadharList;

    this.comeFromApp = Boolean(data.comeFromApp);

    console.log('::aadharList::', this.aadharList);
  }

  ngOnInit() {
    this.aadharListForm = this.fb.group({
      aadharList: ['', Validators.required],
    });
  }

  newAadhar() {
    this.dialog.closeAll();
    this.router.navigate(['auth/aadhar'], {
      queryParams: {
        [this.encrypt.encryptionAES('type')]: this.encrypt.encryptionAES('KYC'),
      },
    });
  }

  changeNumber() {
    this.dialog.closeAll();
  }

  onSubmit() {
    let userId = this.aadharListForm.value.aadharList;
    let getSelectAadharData = this.aadharList.filter(
      (obj: any) => obj.user_entry_id == userId
    );
    this.store.set({
      key: 'userEntryId',
      value: getSelectAadharData[0].user_entry_id.toString(),
    });
    if (getSelectAadharData[0].isKycNeeded == 1) {
      this.dialog.closeAll();
      this.router.navigate(['auth/aadhar'], {
        queryParams: {
          [this.encrypt.encryptionAES('type')]:
            this.encrypt.encryptionAES('REKYC'),
        },
      });
    } else {
      this.loaderService.show();
      this.authService.registerSelectUser(userId).subscribe({
        next: async (res: any) => {
          console.log('response', res);
          if (res.success && res.data) {
            this.dialog.closeAll();
            this.store.set({ key: 'accessToken', value: res.data.accessToken });
            this.store.set({
              key: 'refreshToken',
              value: res.data.refreshToken,
            });
            this.store.set({
              key: 'apiAccessToken',
              value: res.data.apiAccessToken,
            });
            this.store.set({ key: 'customerId', value: res.data.customerId });
            this.store.set({ key: 'authType', value: 'login' });

            if (res.data.stage == 'DASHBOARD') {
              let goal = this.store.get('goal');
              this.store.remove('passcode');
              this.analytics.initializeUser({
                customerId: res.data.customerId,
                authType: 'login',
              });
              this.analytics.sendDataToGoogleAnalytics('web_login', {
                authType: 'login',
                customerId: res.data.customerId,
                user_id: res.data.customerId
              });
              if (this.comeFromApp) {
                this.router.navigate(['app-home'], {
                  queryParams: {
                    type: this.store.get('authType'),
                    status: true,
                    token: this.store.get('apiAccessToken'),
                    customerId: this.store.get('customerId'),
                  },
                });
              } else if (goal == 'repayment') {
                this.router.navigate(['loan/apply']);
              } else {
                this.router.navigate(['home']);
              }
            } else if (res.data.stage == 'SETPASSCODE') {
              this.router.navigate(['more/pass-code']);
              this.store.set({ key: 'passcode', value: 'true' });
              if (this.comeFromApp) {
                this.store.set({ key: 'comeFromApp', value: 'true' });
              }
            }
          } else {
            this.toastr.error(res.message, 'Error!');
          }

          this.store.remove('mobileAccessToken');
          // get and save fcm TOKEN HERE
          try {
            let fcmToken: any = await this.firebaseService.getToken();
            if (fcmToken) {
              this.authService
                .saveFCMToken({ token: fcmToken.toString() })
                .subscribe({
                  next: (res: any) => {},
                });
            }
          } catch (error) {}
          this.loaderService.hide();
        },
      });
    }
  }
}
