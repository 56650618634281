import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from '../services/store.service';
import { AuthService } from '../components/auth/auth.service';
import { LoaderService } from '../services/loader.service';
import { Router } from '@angular/router';
import { LocationService } from '../services/location.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public toastr: ToastrService,
    public store: StoreService,
    public auth: AuthService,
    public loaderService: LoaderService,
    public locationService: LocationService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const urlParts = request.url.split('/');
    const skippedDomainInCaseOfError = [
      'utils.follow.whistle.mobi',
      'thoughversetechnologiespvtltd10422038.o18.click',
    ];

    console.log('url:::', urlParts[urlParts.length - 1]);
    let skip = ['access', 'fcm-token'].includes(urlParts[urlParts.length - 1]);

    // Retrieve tokens from StoreService
    let mobileAccessToken = this.store.get('mobileAccessToken');
    let authAccessToken = this.store.get('accessToken');
    console.log(':::mobileAccessToken', mobileAccessToken);
    let token =
      mobileAccessToken != null ? mobileAccessToken : authAccessToken;

    // Base headers
    let header: any = {
      authorization: `Bearer ${token}`,
      latitude: '22.123454',
      longitude: '22.123454',
      trackingId: '',
      deviceId: '',
      comeFromReferrer: '',
      clientIp: '',
      prospectId: (<any>window).MXCProspectId || ''
    };

    // Add dynamic latitude and longitude from LocationService
    this.locationService.latitude.subscribe((latitudeValue: string) => {
      this.locationService.longitude.subscribe((longitudeValue: string) => {
        if (latitudeValue !== '' && longitudeValue !== '') {
          header.latitude = latitudeValue;
          header.longitude = longitudeValue;
        }
      });
    });

    // Add cookies as headers
    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.split('=').map((c) => c.trim());
      acc[key] = value;
      return acc;
    }, {} as Record<string, string>);

    // Include specific cookies in headers if needed
    if (cookies['trackingId']) {
      header['trackingId'] = cookies['trackingId'];
    }
    if (cookies['clientIp']) {
      header['clientIp'] = cookies['clientIp'];
    }
    if (cookies['referrer']) {
      header['comeFromReferrer'] = cookies['referrer'];
    }
    if (cookies['deviceId']) {
      header['deviceId'] = cookies['deviceId'];
    }
    return next
      .handle(
        request.clone({
          setHeaders: header,
        })
      )
      .pipe(
        tap({
          next: (event) => {
            if (event instanceof HttpResponse) {
              if (event.status == 401) {
                this.store.remove('accessToken');
                if (skip) {
                  this.toastr.error('Unauthorized access!', 'Error!');
                  this.store.clearAuthStore();
                }
                this.router.navigate(['auth/mobile']);
                this.loaderService.hide();
              }
            }
            return event;
          },
          error: (error) => {
            if (error.status === 401) {
              this.store.remove('accessToken');
              if (skip) {
                this.toastr.error('Unauthorized access!', 'Error!');
                this.store.clearAuthStore();
              }
              this.router.navigate(['auth/mobile']);
              this.loaderService.hide();
            } else if (error.status === 403) {
              console.log('test auth', skip);
              this.store.remove('accessToken');
              if (skip) {
                this.toastr.error('Unauthorized access!', 'Error!');
                this.store.clearAuthStore();
              }
              this.router.navigate(['auth/mobile']);
              this.loaderService.hide();
            } else if (error.status === 404) {
              this.toastr.error('Page Not Found!', 'Error!');
              this.loaderService.hide();
            } else if (error.status === 500) {
              this.toastr.error(error.error.message, 'Error!');
              this.loaderService.hide();
            } else {
              console.log('::error', error);
              if (
                urlParts?.length > 2 &&
                skippedDomainInCaseOfError.includes(urlParts[2])
              ) {
                console.log('Error Skipped in case of 3rd party API call');
              } else {
                this.toastr.error(error.error.message, 'Error!');
                this.loaderService.hide();
              }
            }
          },
        })
      );
  }
}