import { Injectable } from '@angular/core';
import { CryptoEncryptionService } from './crypto-encryption.service';
import { Buffer } from "buffer";

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private encrypt: CryptoEncryptionService) { }

  set(obj: { key: string, value: any }) {
    let key = Buffer.from(obj.key, 'binary').toString('base64');
    let value = this.encrypt.encryptionAES(obj.value);
    localStorage.setItem(key, value);
    return true;
  }
  get(key: string) {
    key = Buffer.from(key, 'binary').toString('base64');
    let getData = localStorage.getItem(key);
    if (getData != null) {
      let value = this.encrypt.decryptionAES(getData);
      if (value && value != '') {
        return value;
      }
    }
    return null;
  }
  remove(key: string) {
    key = Buffer.from(key, 'binary').toString('base64');
    localStorage.removeItem(key);
    return true;
  }
  clearAuthStore() {
    const getDKey = (key: string) => {
      return Buffer.from(key, 'binary').toString('base64');
    }
    localStorage.removeItem(getDKey("accessToken"));
    localStorage.removeItem(getDKey("refreshToken"));
    localStorage.removeItem(getDKey("companyDetails"));
    localStorage.removeItem(getDKey("credittScore"));
    localStorage.removeItem(getDKey("apiAccessToken"));
    localStorage.removeItem(getDKey("customerId"));
    localStorage.removeItem(getDKey("userEntryId"));
    localStorage.removeItem(getDKey("passcode"));
    localStorage.removeItem(getDKey("authType"));
    localStorage.removeItem(getDKey("comeFromApp"));
    localStorage.removeItem(getDKey("loanId"));
  }
}
