import { Injectable } from '@angular/core';
import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties, Analytics } from 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { FirebaseError } from 'firebase/app';
import { StoreService } from './store.service';
declare var fbq: any;
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private eventApp: FirebaseApp;
  private analytics: Analytics;

  constructor(private store: StoreService) {
    try {
      this.eventApp = getApp('eventsApp');
    } catch (error) {
      if (error instanceof FirebaseError && error.code === 'app/no-app') {
        // If the app doesn't exist, initialize it
        this.eventApp = initializeApp(environment.firebaseEventConfig, 'eventsApp');
      } else {
        throw error; // re-throw other errors
      }
    }

    // Initialize the Firebase Analytics instance for the event app
    this.analytics = getAnalytics(this.eventApp);
  }

  async initializeUser(data: any): Promise<boolean> {
    try {
      await setUserProperties(this.analytics, data);
      return true;
    } catch (error) {
      console.error('Error setting user properties:', error);
      return false;
    }
  }
  removeUndefinedOrNull(obj: any) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  }

  sendDataToGoogleAnalytics(event: string, data: Object) {
    try {
      let sendAnalyticsData = JSON.parse(JSON.stringify(data));
      const utmParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_id',
        'utm_term',
        'utm_content',
        'click_id'
      ];
      utmParams.forEach(param => {
        const paramValue = this.store.get(param);
        if (paramValue) {
          sendAnalyticsData[param] = paramValue;
        }
      });
      logEvent(this.analytics, event, sendAnalyticsData);
    } catch (err) {
      console.error('Error sending data to Google Analytics:', err);
    }
    try {
      fbq('track', event, this.removeUndefinedOrNull(data));
    } catch (error) {
      console.log(error, "Fb Event issue");
    }
  }
}
