export const environment = {
  production: true,
  theme: 'light', // light // dark
  baseUrl: 'https://webuat.credittapi.in/web/',
  serviceWorkerStatus: true,
  pixelMarketingEventWhistleURL: 'https://utils.follow.whistle.mobi/pixel.php',
  thoughverseMarketingURL: 'https://thoughversetechnologiespvtltd10422038.o18.click/p',
  appDeeplink: 'credittnow://auth',
  firebaseConfig: {
    apiKey: "AIzaSyDWRpriUobnctUbPqHBRgVmCdcaRrrdvCA",
    authDomain: "creditt-c79c5.firebaseapp.com",
    databaseURL: "https://creditt-c79c5.firebaseio.com",
    projectId: "creditt-c79c5",
    storageBucket: "creditt-c79c5.appspot.com",
    messagingSenderId: "780950526191",
    appId: "1:780950526191:web:acbe99f4608be44611a31a",
    measurementId: "G-L5CWEWVFLW"
  },
  firebaseEventConfig: {
    apiKey: "AIzaSyASaFnGbsV1I-exYNc-dTn2qvacuyOhguM",
    authDomain: "credittnow-app.firebaseapp.com",
    projectId: "credittnow-app",
    storageBucket: "credittnow-app.appspot.com",
    messagingSenderId: "812008968705",
    appId: "1:812008968705:web:2590b2bbf4227ca8686600",
    measurementId: "G-XG42XCEF40"
  },
  oldRepaymentRedirection: 'https://webviewsuat.credittapi.in/razorpay/',
  criteoTagAccountId: '117914'
};
