<!--  start Dashboard box -->
<div class="home-layout-main isHomeLayout">
  <div class="home-app-header">
    <app-header
      [title]="common.customerNickName"
      [isShowBackButton]="false"
    ></app-header>
  </div>
  <div class="home-content-main">
    <div class="homeHeader" *ngIf="showInstallButton">
      <div class="install-sec card-view" *ngIf="showInstallButton">
        <img [src]="icon.linkDownloadIc" alt="" />
        <p>
          Install Web App
          <span class="link-text" (click)="installApplication()"
            >Click Here
          </span>
        </p>
      </div>
    </div>

    <div class="home-main-card is-dashboard">
      <div class="card-view sub-card">
        <div class="icon-sec">
          <img [src]="icon.homeBorrowIcon" />
        </div>
        <div class="card-content-sec">
          <h4>Borrow</h4>
          <p>Approval in just a few clicks</p>
          <div class="btn-sec">
            <button (click)="openLoan()" class="card-view-button">
              Dashboard
            </button>
          </div>
        </div>
      </div>
      <div class="card-view sub-card invest-card">
        <div class="icon-sec">
          <img [src]="icon.homeInvestIcon" />
        </div>
        <div class="card-content-sec">
          <h4>Invest</h4>
          <p>Invest In Mutuals Funds for financial Independence</p>
          <div class="btn-sec">
            <button (click)="openInvestment()" class="card-view-button">
              Invest Now
            </button>
          </div>
        </div>
      </div>
      <div class="card-view sub-card">
        <div class="icon-sec">
          <img [src]="icon.homeCredittScoreIcon" />
        </div>
        <div class="card-content-sec">
          <h4>Creditt<sup>+</sup> Score</h4>
          <p>Check Your Creditt<sup>+</sup> Score</p>
          <div class="btn-sec">
            <button (click)="openCredittScore()" class="card-view-button">
              View Score
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="homeHeader">
</div> -->
