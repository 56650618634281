import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Icons } from 'src/app/constants/icons';
import { CommonService } from 'src/app/services/common.service';
import { HomeService } from './home.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader.service';
import { PwaInstallService } from 'src/app/services/pwa-install.service';
import { StoreService } from 'src/app/services/store.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  icon: any;
  deferredPrompt: any;
  showInstallButton = false;

  constructor(
    private router: Router,
    public common: CommonService,
    public homeService: HomeService,
    public toastr: ToastrService,
    public loaderService: LoaderService,
    public commonService: CommonService,
    private pwaService: PwaInstallService,
    private store: StoreService,
    private analytics: AnalyticsService
  ) {
    this.icon = Icons;

    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.deferredPrompt = event;
      this.showInstallButton = true;
    });

    window.addEventListener('appinstalled', () => {
      this.showInstallButton = false;
    });

    this.commonService.headerDetail$.next({ mainTitle: '' });
  }

  ngOnInit() {
    this.loaderService.show();
    this.homeService.getDashboardData({ languageId: 'en' }).subscribe({
      next: (res: any) => {
        if (res.success && res.data) {
          this.commonService.customerNickName = res.data.nickName;
        } else {
          this.toastr.error(res.message, 'Error!');
        }
        this.loaderService.hide();
      },
    });
  }

  openLoan() {
    this.router.navigate(['loan/apply']);
  }
  openInvestment() {
    this.router.navigate(['investment']);
  }
  openCredittScore() {
    this.router.navigate(['creditt-score']);
  }

  installApplication() {
    let customerId = this.store.get('customerId') || undefined;
    let loanId = this.store.get('loanId') || undefined;
    this.analytics.sendDataToGoogleAnalytics('PWA_Downloaded', {
      customerId,
      loanId,
    });

    this.pwaService.promptInstall();
  }
}
