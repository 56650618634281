import { Component } from '@angular/core';
import { InfoMessageComponent } from '../dialogs/info-message/info-message.component';
import { LocationInfoComponent } from '../dialogs/location-info/location-info.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { StoreService } from 'src/app/services/store.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from '../auth.service';
import { Icons } from 'src/app/constants/icons';
import { MobileOtpComponent } from '../dialogs/mobile-otp/mobile-otp.component';
import { MessagingService } from 'src/app/services/messaging.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { InstallPwaDialogComponent } from '../dialogs/install-pwa-dialog/install-pwa-dialog.component';
import { PwaInstallService } from 'src/app/services/pwa-install.service';
import { FirebaseMessagingService } from 'src/app/services/firebase-messaging.service';
import { LocationService } from 'src/app/services/location.service';
import { Lottie } from 'src/app/constants/lottie';
import { homepage } from 'src/app/constants/meta-data';
import { Meta, Title } from '@angular/platform-browser';
import { CriteoTagService } from 'src/app/services/criteo-tag.service';
interface Navigator {
  standalone?: boolean;
}

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class MobileComponent {
  icon: any;
  mobileForm!: FormGroup;
  isDisable: boolean = false;
  currentUrl: string = '';
  deferredPrompt: any;
  showInstallButton = false;
  openInstallDialog = true;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public lottie: Lottie,
    private store: StoreService,
    public toastr: ToastrService,
    private router: Router,
    public loaderService: LoaderService,
    public messagingService: MessagingService,
    private authService: AuthService,
    private analytics: AnalyticsService,
    private pwaService: PwaInstallService,
    private firebaseService: FirebaseMessagingService,
    private locationService: LocationService,
    private titleService: Title,
    private metaService: Meta,
    public criteoTagService: CriteoTagService
  ) {
    this.icon = Icons;

    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.deferredPrompt = event;
      this.showInstallButton = true;

      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome == 'dismissed') {
          this.openInstallDialog = false;
        }
      });
      if (this.openInstallDialog) this.openInstallAppDialog();
    });

    window.addEventListener('appinstalled', () => {
      this.showInstallButton = false;
    });
  }

  async ngOnInit() {

    // call here criteo tag service
    this.criteoTagService.fireHomepageTag('');

    const isStandalone =
      (window.navigator as any).standalone ||
      window.matchMedia('(display-mode: standalone)').matches;

    if (isStandalone) {
      this.showInstallButton = false;
    }
    const metaTags = homepage;
    this.titleService.setTitle(metaTags.title);
    metaTags.meta.forEach((tag: any) => this.metaService.addTag(tag));


    let getToken = this.store.get('accessToken');
    let customerId = this.store.get('customerId') || undefined;
    let loanId = this.store.get('loanId') || undefined;
    this.analytics.sendDataToGoogleAnalytics('web_login_page_load', {
      authType: 'login',
      customerId,
      loanId,
    });
    this.currentUrl = this.router.url;
    // if (
    //   this.store.get('source_inquiry_id') == null &&
    //   this.currentUrl != '/auth/app'
    // ) {
    //   this.router.navigate(['source-inquiry']);
    // }

    if (getToken != null && this.currentUrl == '/auth/app') {
      this.store.clearAuthStore();
    }

    this.mobileForm = this.fb.group({
      mobile: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(12),
        ],
      ],
      comeFromApp: [this.currentUrl == '/auth/app' ? true : false],
    });

    let refreshToken = this.store.get('refreshToken');
    if (refreshToken != null && this.currentUrl != '/auth/app') {
      this.setNewAccessToken();
    }
  }

  ngAfterViewInit() {
    let width: string = window.innerWidth <= 768 ? '90%' : '50%';
    if (this.currentUrl == '/auth/app') {
      this.store.set({ key: 'platform', value: 'app' });
      this.store.set({ key: 'comeFromApp', value: 'true' });
    } else {
      this.dialog.open(LocationInfoComponent, {
        width: width,
        disableClose: true,
      });
    }
  }

  setNewAccessToken() {
    this.loaderService.show();
    return new Promise((resolve) => {
      this.authService
        .getAccessToken({
          refreshToken: '' + this.store.get('refreshToken'),
        })
        .subscribe({
          next: async (res: any) => {
            if (res.success && res.data && res.data.accessToken) {
              this.store.remove('mobileAccessToken');
              this.store.set({
                key: 'accessToken',
                value: res.data.accessToken,
              });
              try {
                let fcmToken: any = await this.firebaseService.getToken();
                if (fcmToken) {
                  this.authService
                    .saveFCMToken({ token: fcmToken.toString() })
                    .subscribe({
                      next: (res: any) => {},
                    });
                }
              } catch (error) {}
              this.loaderService.hide();
              this.router.navigate(['loan/apply']);
              return resolve(true);
            } else {
              this.store.clearAuthStore();
              this.router.navigate(['auth/mobile']);
            }
            this.loaderService.hide();
            return resolve(false);
          },
          error: (err: any) => {
            this.router.navigate(['auth/mobile']);
            this.store.clearAuthStore();
          },
        });
    });
  }

  keyUpEvent(event: any) {
    const validCharacters = /^[0-9]*$/;
    if (!validCharacters.test(event.target.value)) {
      this.mobileForm.patchValue({
        mobile: event.target.value.replace(/[^0-9-_]/g, ''),
      });
    }
  }

  onSubmit() {
    this.isDisable = true;
    this.loaderService.show();
    let mobileNumber = this.mobileForm.value.mobile;
    this.authService
      .registerMobile({
        mobile: mobileNumber,
        comeFromApp: Boolean(this.mobileForm.value.comeFromApp),
      })
      .subscribe({
        next: (res: any) => {
          this.isDisable = false;
          if (res.success) {
            let width: string = window.innerWidth <= 768 ? '90%' : '40%';
            this.dialog.open(MobileOtpComponent, {
              data: {
                mobileNo: mobileNumber,
                comeFromApp: Boolean(this.mobileForm.value.comeFromApp),
              },
              width: width,
              disableClose: true,
            });
          } else {
            this.toastr.error(res.message, 'Error!');
          }
          this.loaderService.hide();
        },
      });
  }

  openInstallAppDialog() {
    // let width: string = window.innerWidth <= 768 ? '35%' : '20%';
    let width: string = window.innerWidth <= 768 ? '90%' : '40%';

    this.dialog.open(InstallPwaDialogComponent, {
      width: width,
      disableClose: true,
    });
  }

  installApplication() {
    let customerId = this.store.get('customerId') || undefined;
    let loanId = this.store.get('loanId') || undefined;
    this.analytics.sendDataToGoogleAnalytics('PWA_Downloaded', {
      customerId,
      loanId,
    });

    this.pwaService.promptInstall();
  }
}
