import { Component, Inject, Input, ViewChildren,OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Icons } from 'src/app/constants/icons';
import { AadharListComponent } from '../aadhar-list/aadhar-list.component';
import { AuthService } from '../../auth.service';
import { MobileComponent } from '../../mobile/mobile.component';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader.service';
import { StoreService } from 'src/app/services/store.service';
import { CryptoEncryptionService } from 'src/app/services/crypto-encryption.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { FirebaseMessagingService } from 'src/app/services/firebase-messaging.service';
import { MarketingEventWhistleService } from 'src/app/services/marketing-event-whistle.service';
import { CriteoTagService } from 'src/app/services/criteo-tag.service';

@Component({
  selector: 'app-mobile-otp',
  templateUrl: './mobile-otp.component.html',
  styleUrls: ['./mobile-otp.component.scss'],
})
export class MobileOtpComponent  implements OnInit {
  icon: any;
  otpForm!: FormGroup;
  userMobileNumber: any = '';
  input: any;
  type: number = 1;
  comeFromApp: boolean = false;
  utm_params: any;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    public toastr: ToastrService,
    public loaderService: LoaderService,
    public store: StoreService,
    public encrypt: CryptoEncryptionService,
    private firebaseService: FirebaseMessagingService,
    public dialogRef: MatDialogRef<MobileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private analytics: AnalyticsService,
    private marketingevent: MarketingEventWhistleService,
    public criteoTagService: CriteoTagService
  ) {
    this.icon = Icons;
    this.otpForm = this.toFormGroup(this.formInput);
    setTimeout(() => {
      this.rows._results[0].nativeElement.focus();
    }, 100);
    this.userMobileNumber = data.mobileNo;
    this.comeFromApp = Boolean(data.comeFromApp);
    this.type = data.type;
    this.input = data.input;
    this.startTimer();
  }
  ngOnInit(): void {
    // this.detectClipboardOtp();
  }

  formInput = ['otp1', 'otp2', 'otp3', 'otp4', 'otp5', 'otp6'];
  @ViewChildren('formRow') rows: any;

  toFormGroup(elements: any) {
    const group: any = {};
    elements.forEach((key: any) => {
      group[key] = new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(1),
        Validators.maxLength(1),
      ]);
    });
    return new FormGroup(group);
  }

  async detectClipboardOtp() {
    const items = await navigator.clipboard.read(),
      textBlob = await items[0].getType("text/plain"),
      text = await (new Response(textBlob)).text(),
      otpAry = text.split(''),
      len = this.formInput.length;
      
      if (Number(text) && len == 6) {
        for(let i=0; i<= len; i++) {
          const inputField = this.otpForm.get(this.formInput[i]);
          if (inputField && !inputField.value) inputField.setValue(otpAry[i]);
        }
        await navigator.clipboard.writeText('')
        const allFieldsFilled = this.formInput.every(field => this.otpForm.get(field)?.value);
        if (allFieldsFilled) this.onSubmit();
      }
  }

  keyUpEvent(event: any, index: any) {
    const validCharacters = /^[0-9]*$/;
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
    } else {
      pos = index + 1;
    }
    if (pos > -1 && pos < this.formInput.length) {
      if (
        (this.rows._results[index].nativeElement.value != '' &&
          validCharacters.test(
            this.rows._results[index].nativeElement.value
          )) ||
        (event.keyCode === 8 && event.which === 8)
      ) {
        this.rows._results[pos].nativeElement.focus();
      } else {
        this.rows._results[index].nativeElement.value = '';
      }
    }
  }

  timeLeft: number = 0;
  interval: any;
  startTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  resendOtp() {
    this.startTimer();
    this.loaderService.show();
    if (this.input && this.type) {
      this.authService
        .authRepayment({
          input: this.input,
          type: this.type,
        })
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              this.toastr.success(res.message, 'Success!');
              this.otpForm.patchValue({
                otp1: '',
                otp2: '',
                otp3: '',
                otp4: '',
                otp5: '',
                otp6: '',
              });
              this.rows._results[0].nativeElement.focus();
            } else {
              this.toastr.error(res.message, 'Error!');
            }
            this.loaderService.hide();
          },
        });
    } else {
      this.authService
        .registerMobile({
          mobile: this.userMobileNumber,
          comeFromApp: Boolean(this.comeFromApp),
        })
        .subscribe({
          next: (res: any) => {
            if (res.success) {
              this.toastr.success(res.message, 'Success!');
              this.otpForm.patchValue({
                otp1: '',
                otp2: '',
                otp3: '',
                otp4: '',
                otp5: '',
                otp6: '',
              });
              this.rows._results[0].nativeElement.focus();
            } else {
              this.toastr.error(res.message, 'Error!');
            }
            this.loaderService.hide();
          },
        });
    }
  }

  editMobileNumber() {
    this.dialog.closeAll();
  }

  onSubmit() {
    if (
      this.otpForm.value.otp1 != '' &&
      this.otpForm.value.otp2 != '' &&
      this.otpForm.value.otp3 != '' &&
      this.otpForm.value.otp4 != '' &&
      this.otpForm.value.otp5 != '' &&
      this.otpForm.value.otp6 != ''
    ) {
      this.loaderService.show();
      let otp =
        '' +
        this.otpForm.value.otp1 +
        this.otpForm.value.otp2 +
        this.otpForm.value.otp3 +
        this.otpForm.value.otp4 +
        this.otpForm.value.otp5 +
        this.otpForm.value.otp6;
      
        this.utm_params = {};
        const utmParams = [
          'utm_source',
          'utm_medium',
          'utm_campaign',
          'utm_id',
          'utm_term',
          'utm_content',
          'click_id'
        ];
        console.log('utm_source:::',this.store.get('utm_source'));
        utmParams.forEach(param => {
          const paramValue = this.store.get(param);
          if (paramValue) {
            this.utm_params[param] = paramValue;
          }
        });

      this.authService
        .registerVerifyOtp({
          mobile: this.userMobileNumber,
          otp: otp,
          comeFromApp: Boolean(this.comeFromApp),
          type: this.type,
          input: this.input,
          utm_params: this.utm_params
        })
        .subscribe({
          next: async (res: any) => {
            console.log('::res.data::', res.data);

            if (res.success && res.data) {
              this.dialog.closeAll();
              this.store.set({
                key: 'mobileAccessToken',
                value: res.data.mobileaccesstoken,
              });
              // leadId
              console.log("::::::::::::leadId res.data",res.data);
              
              this.setProspectParameters(res.data.leadId);

              if (res?.data?.user?.user_not_registered_id)
                this.store.set({
                  key: 'userNotRegisterId',
                  value: res?.data?.user[0]?.user_not_registered_id.toString(),
                });
              if (res?.data?.user?.user_not_registered_id)
                this.marketingevent.pixelMarketingEventAPI(
                  res?.data?.user[0]?.user_not_registered_id.toString(),
                  '',
                  'Mobile_OTP'
                );
              // check aadhar_number and send as product id to criteo
              this.criteoTagService.fireProductTag(res?.data?.user[0]?.customer_id || res?.data?.user[0]?.customerId, 1);

              if (res.data.stage == 'SELECT_USER') {
                let width: string = window.innerWidth <= 768 ? '90%' : '40%';
                this.dialog.open(AadharListComponent, {
                  width: width,
                  data: {
                    aadharList: res.data.user,
                    comeFromApp: Boolean(this.comeFromApp),
                  },
                  disableClose: true,
                });
              } else if (res.data.stage == 'AADHAR_VERIFICATION') {
                this.router.navigate(['auth/aadhar'], {
                  queryParams: {
                    [this.encrypt.encryptionAES('type')]:
                      this.encrypt.encryptionAES('KYC'),
                  },
                });
              } else if (res.data.stage == 'DASHBOARD') {
                this.store.remove('passcode');
                this.store.set({
                  key: 'accessToken',
                  value: res.data.accessToken,
                });
                this.store.set({
                  key: 'refreshToken',
                  value: res.data.refreshToken,
                });
                this.store.set({
                  key: 'apiAccessToken',
                  value: res.data.apiAccessToken,
                });
                this.store.remove('mobileAccessToken');
                this.store.set({
                  key: 'customerId',
                  value: res.data.customerId,
                });
                this.store.set({ key: 'authType', value: 'login' });
                this.analytics.initializeUser({
                  customerId: res.data.customerId,
                  authType: 'login',
                });
                this.analytics.sendDataToGoogleAnalytics('web_login', {
                  authType: 'login',
                  customerId: res.data.customerId,
                  user_id: res.data.customerId
                });
                try {
                  let fcmToken: any = await this.firebaseService.getToken();
                  if (fcmToken) {
                    this.authService
                      .saveFCMToken({ token: fcmToken.toString() })
                      .subscribe({
                        next: (res: any) => {},
                      });
                  }
                } catch (error) {}
                this.router.navigate(['loan/apply']);
              } else if (res.data.stage == 'SETPASSCODE') {
                this.router.navigate(['more/pass-code']);
                this.store.set({ key: 'passcode', value: 'true' });
                if (this.comeFromApp) {
                  this.store.set({ key: 'comeFromApp', value: 'true' });
                }
              } else {
                this.router.navigate(['auth/register']);
              }
            } else {
              this.toastr.error(res.message, 'Error!');
            }
            this.loaderService.hide();
          },
        });
    }
  }
  setProspectParameters(leadId: any) {
    if ((<any>window).MXCProspectId !== leadId) {
      let expiryDate = new Date();
      expiryDate.setTime(expiryDate.getTime() + (365 * 24 * 60 * 60 * 1000));
      document.cookie = 'ORG' + '60240' + '=' + leadId + ';expires=' + expiryDate.toUTCString() + ';Domain=' + 'credittnow.com' + ';path=/;';
      (<any>window).MXCProspectId = leadId;
      (<any>window).leadsquared.tracker.pids['60240'] = leadId;
    }
    return true;
  }
}
