import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

interface CartItem {
  id: string;
  price: number;
  quantity: number;
}

interface SalesItem extends CartItem { }

@Injectable({
  providedIn: 'root',
})
export class CriteoTagService {
  private loadedScripts: Set<string> = new Set();
  private initializedTags: Set<string> = new Set();

  constructor() { }

  // Load external script (Dynamic Loader)
  loadCriteoScript(): Promise<void> {
    const scriptUrl = `//dynamic.criteo.com/js/ld/ld.js?a=${environment.criteoTagAccountId}`;
    console.log('scriptUrl ', scriptUrl)
    if (this.loadedScripts.has(scriptUrl)) {
      return Promise.resolve(); // Already loaded
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.async = true;
      script.onload = () => {
        this.loadedScripts.add(scriptUrl);
        resolve();
      };
      script.onerror = () => reject(`Failed to load script: ${scriptUrl}`);
      document.head.appendChild(script);
    });
  }

  // Helper function to push a Criteo event
  private pushCriteoEvent(event: any): void {
    (window as any)['criteo_q'] = (window as any)['criteo_q'] || [];
    (window as any)['criteo_q'].push(event);
  }

  // Homepage Tag
  fireHomepageTag(email: string): void {
    if (this.initializedTags.has('homepage')) return; // Prevent duplicate firing
    const deviceType = this.getDeviceType();
    this.pushCriteoEvent({
      event: 'setAccount',
      account: environment.criteoTagAccountId,
    });
    this.pushCriteoEvent({
      event: 'setEmail',
      email,
    });
    this.pushCriteoEvent({
      event: 'setSiteType',
      type: deviceType,
    });
    this.pushCriteoEvent({
      event: 'viewHome',
    });

    this.initializedTags.add('homepage');
  }

  // Product Tag
  fireProductTag(email: string, productId: any): void {
    productId = productId ? parseInt(productId) : 1;
    if (this.initializedTags.has('product-' + productId)) return;

    const deviceType = this.getDeviceType();
    this.pushCriteoEvent({
      event: 'setAccount',
      account: environment.criteoTagAccountId,
    });
    this.pushCriteoEvent({
      event: 'setEmail',
      email,
    });
    this.pushCriteoEvent({
      event: 'setSiteType',
      type: deviceType,
    });
    this.pushCriteoEvent({
      event: 'viewItem',
      item: productId,
    });

    this.initializedTags.add('product-' + productId);
  }

  // Add to Cart Tag
  fireAddToCartTag(email: string, cartItems: any): void {
    if (this.initializedTags.has('add-to-cart')) return;

    const deviceType = this.getDeviceType();
    this.pushCriteoEvent({
      event: 'setAccount',
      account: environment.criteoTagAccountId,
    });
    this.pushCriteoEvent({
      event: 'setEmail',
      email,
    });
    this.pushCriteoEvent({
      event: 'setSiteType',
      type: deviceType,
    });
    this.pushCriteoEvent({
      event: 'addToCart',
      item: cartItems,
    });

    this.initializedTags.add('add-to-cart');
  }

  // Sales Tag
  fireSalesTag(email: string, transactionId: any, salesItems: any): void {
    transactionId = transactionId ? parseInt(transactionId) : 1;
    if (this.initializedTags.has('sales-' + transactionId)) return;

    const deviceType = this.getDeviceType();
    this.pushCriteoEvent({
      event: 'setAccount',
      account: environment.criteoTagAccountId,
    });
    this.pushCriteoEvent({
      event: 'setEmail',
      email,
    });
    this.pushCriteoEvent({
      event: 'setSiteType',
      type: deviceType,
    });
    this.pushCriteoEvent({
      event: 'trackTransaction',
      id: transactionId,
      item: salesItems,
    });

    this.initializedTags.add('sales-' + transactionId);
  }

  // Helper to determine device type
  private getDeviceType(): string {
    return /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
        ? 'm'
        : 'd';
  }
}
