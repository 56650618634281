import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from './services/loader.service';
import { Icons } from './constants/icons';
import { LocationService } from './services/location.service';
import { environment } from 'src/environments/environment';
import { StoreService } from 'src/app/services/store.service';
import { AuthService } from 'src/app/components/auth/auth.service';
import { CriteoTagService } from './services/criteo-tag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'creditt-web-app';
  permission: boolean = true;
  icon: any;
  apiCalled: boolean = false;
  locationPermission: boolean = false;
  params: any = '';

  constructor(
    public loaderService: LoaderService,
    public locationService: LocationService,
    private store: StoreService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private criteoTagService: CriteoTagService
  ) {
    this.params = window.location.search;
    this.icon = Icons;
    if (environment.production == true) {
      window.console.log = function () { };
      window.console.error = function () { };
      window.console.warn = function () { };
      window.console.time = function () { };
      window.console.timeEnd = function () { };
    }
  }

  async ngOnInit() {

    this.criteoTagService.loadCriteoScript().then(() => {
      console.log('Criteo script loaded successfully.');
    }).catch((error) => {
      console.error('Error loading Criteo script:', error);
    });

    this.saveQueryParamsToStorageAndCookie();
    if (window.location.pathname != '/auth/app') this.locationService.checkPermission();
    if (('serviceWorker' in navigator) && !environment.serviceWorkerStatus) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister().then((boolean) => {
            if (boolean) {
              console.log('Service worker unregistered successfully.');
            } else {
              console.log('Service worker unregistration failed.');
            }
          });
        }
      });
    }
  }

  // Save query parameters to storage and cookies
  private saveQueryParamsToStorageAndCookie() {
    let queryParams = new URLSearchParams(this.params);
    let paramsObj: any = {};

    queryParams.forEach((value, key) => {
      paramsObj[key] = value;
    });

    this.saveParamsToStorage(paramsObj, [
      'utm_source', 'utm_medium', 'utm_campaign', 'utm_id', 'utm_term', 'utm_content', 'click_id'
    ]);
    this.saveParamsToCookies(paramsObj, [
      'trackingId', 'clientIp', 'referrer', 'deviceId', 'code'
    ]);

    // If cookies are missing, handle the user source
    const cookies = this.getCookies();
    if (!this.apiCalled && (!cookies['trackingId'] || !cookies['deviceId'])) {
      this.handleUserSource(paramsObj);
    }
  }

  // Save parameters to storage
  private saveParamsToStorage(queryParams: any, params: string[]) {
    params.forEach((param) => {
      const value = queryParams[param];
      if (value) {
        this.store.set({ key: param, value });
      }
    });
  }

  // Save parameters to cookies
  private saveParamsToCookies(queryParams: any, params: string[]) {
    params.forEach((param) => {
      const value = queryParams[param];
      if (value) {
        this.setCookie(param, value);
      }
    });
  }

  // Get cookies as an object
  private getCookies(): Record<string, string> {
    return document.cookie.split(';').reduce((acc, cookie) => {
      const [key, value] = cookie.split('=').map((c) => c.trim());
      acc[key] = value;
      return acc;
    }, {} as Record<string, string>);
  }

  // Handle user source based on 'utm_source' query parameter
  private handleUserSource(queryParams: any) {
    // Check if 'utm_source' is 'google' and handle the logic accordingly
    const utmSource = queryParams['utm_source'];
    if (utmSource) {
      this.sendDataToApi(queryParams);  // Send data if utm_source is 'google'
    } else {
      this.sendDataToApi();  // Otherwise, send default data
    }
  }

  // Send the data to the API
  private sendDataToApi(queryParams: any = {}) {
    this.apiCalled = true;
    const data = {
      utm_source: queryParams['utm_source'] || 'direct',
      utm_medium: queryParams['utm_medium'] || 'direct',
      utm_campaign: queryParams['utm_campaign'] || 'none',
      utm_id: queryParams['utm_id'] || 'none',
      utm_term: queryParams['utm_term'] || 'none',
      utm_content: queryParams['utm_content'] || 'none',
      gclid: queryParams['gclid'] || 'none',
      click_id: queryParams['click_id'] || '',
    };
    // Call the userRedirect method of authService
    this.authService.userRedirect(data).subscribe({
      next: (res: any) => {
        console.log('API response:', res);
        if (res.success) {
          this.saveParamsToCookies(res.data, [
            'trackingId', 'clientIp', 'referrer', 'deviceId', 'code'
          ]);
        }
      },
      error: (err) => {
        console.error('API error:', err);
      },
    });
  }

  // Set a cookie
  private setCookie(name: string, value: string, days: number = 30) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Expiry in milliseconds
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
  }

}