import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Icons } from 'src/app/constants/icons';
import { AuthService } from '../auth.service';
import { SelfieComponent } from '../dialogs/selfie/selfie.component';
import { filter } from 'rxjs';
import { EmailVerificationComponent } from '../dialogs/email-verification/email-verification.component';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from 'src/app/services/store.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import * as moment from 'moment';
import { FirebaseMessagingService } from 'src/app/services/firebase-messaging.service';
import { CriteoTagService } from 'src/app/services/criteo-tag.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  icon: any;
  imageUrl: string = Icons.malePlaceholder;
  registerForm!: FormGroup;
  selfieDialogRef!: MatDialogRef<SelfieComponent>;
  isProfileSelected : Boolean = false;
  isSubmitting = false;
  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public loaderService: LoaderService,
    public store: StoreService,
    public toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private analytics: AnalyticsService,
    private firebaseService: FirebaseMessagingService,
    public criteoTagService: CriteoTagService
  ) {
    this.icon = Icons;
  }

  ngOnInit() {
    this.registerForm = this.fb.group({
      customerName: ['', Validators.required],
      customerMobile: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          Validators.maxLength(12),
        ],
      ],
      customerEmail: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      customerDob: ['', [Validators.required]],
      customerGender: ['', Validators.required],
      MonthlyTakeHomeSalary: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(4),
          Validators.maxLength(7),
        ],
      ],
      customerAddress: ['', Validators.required],
      confirmCheckBox: [false, Validators.requiredTrue],
    });

    this.getRegisterProfile();
  }

  onCheckboxClick(event: MouseEvent) {
    console.log(this.isProfileSelected,event);
    if (this.isProfileSelected === false) {
      event.preventDefault(); 
      this.registerForm.get('confirmCheckBox')?.setValue(false);
      this.toastr.error("Please upload profile picture.", 'Error!');
    }
  }

  getRegisterProfile() {
    this.loaderService.show();
    this.authService.getRegisterProfile().subscribe({
      next: (res: any) => {
        if (res.success && res.data) {
          this.isProfileSelected = res.data.user_profile? true : false;
          this.imageUrl =
            res.data.user_profile != '' && res.data.user_profile != null
              ? res.data.user_profile
              : this.imageUrl;
          this.registerForm.patchValue({
            customerName: res.data.name,
            customerMobile: res.data.mobile,
            customerEmail: res.data.email,
            customerDob: moment(res.data.dob).format('DD/MM/YYYY'),
            customerGender:
              res.data.gender == 'M'
                ? 'Male'
                : res.data.gender == 'F'
                ? 'Female'
                : 'Other',
            customerAddress: res.data.user_address,
          });
        } else {
          this.toastr.error(res.message, 'Error!');
        }
        this.loaderService.hide();
      },
      error: (err: any) => {
        this.loaderService.hide();
      },
    });
  }

  openCamera() {
    let width: string = window.innerWidth <= 768 ? '80%' : '50%';
    this.selfieDialogRef = this.dialog.open(SelfieComponent, {
      width: width,
      data: { type: 'profile' },
      disableClose: true,
    });

    this.selfieDialogRef
      .afterClosed()
      .pipe(filter((image) => image))
      .subscribe((image) => {
        this.isProfileSelected = true;
        this.imageUrl = image;
        this.cdr.detectChanges();
      });
  }

  verifyEmail() {
    let width: string = window.innerWidth <= 768 ? '90%' : '30%';
    const dialogRef = this.dialog.open(EmailVerificationComponent, {
      width: width,
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        if (response.status == true) {
          this.registerForm.patchValue({
            customerEmail: response.email,
          });
        }
      }
    });
  }

  salaryOnChange(event: any) {
    const validCharacters = /^[0-9]*$/;
    if (!validCharacters.test(event.target.value)) {
      this.registerForm.patchValue({
        MonthlyTakeHomeSalary: event.target.value.replace(/[^0-9-_]/g, ''),
      });
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    this.loaderService.show();
    if (this.registerForm.value.MonthlyTakeHomeSalary != '') {
      this.authService
        .register({
          salary: Number(this.registerForm.value.MonthlyTakeHomeSalary),
        })
        .subscribe({
          next: async (res: any) => {
            if (res && res.success && res.data) {
              if (typeof res.data.user_entry_id == 'number') {
                res.data.user_entry_id = res.data.user_entry_id + "";
              };
              this.store.set({
                key: 'accessToken',
                value: res.data.accessToken,
              });

              this.store.set({
                key: 'refreshToken',
                value: res.data.refreshToken,
              });
              this.store.set({
                key: 'apiAccessToken',
                value: res.data.apiAccessToken,
              });

              // check and send criteo event
              this.criteoTagService.fireProductTag(res?.data?.customerId || '', 1);

              if (res?.data?.customerId)
                this.store.set({
                  key: 'customerId',
                  value: res.data.customerId,
                });
              this.store.set({ key: 'authType', value: 'register' });
              this.store.remove('mobileAccessToken');
              this.store.set({
                key: 'userEntryId',
                value: res.data.user_entry_id,
              });
              if (res.data.stage == 'SETPASSCODE') {
                this.loaderService.hide();
                this.router.navigate(['auth/pass-code']);
              } else {
                this.loaderService.hide();
                this.router.navigate(['home']);
              }
              try {
                this.firebaseService.getToken().then((token: any) => {
                  if (token) {
                    this.authService
                      .saveFCMToken({ token: token.toString() })
                      .subscribe({
                        next: (res: any) => { },
                      });
                  }
                });
              } catch (error) { }
              this.analytics.initializeUser({
                customerId: res?.data?.customerId ? res?.data?.customerId : '',
              });
              this.analytics.sendDataToGoogleAnalytics('web_register', {
                authType: 'register',
                customerId: res?.data?.customerId ? res?.data?.customerId : '',
                user_id: res?.data?.customerId ? res?.data?.customerId : ''
              });
            } else {
              this.toastr.error(res.message, 'Error!');
            }
            this.loaderService.hide();
          },
          error: (err: any) => {
            this.loaderService.hide();
          },
        });
    } else {
      this.toastr.error('Salary is required.', 'ERROR!');
    }
  }
}
